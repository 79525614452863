'use strict';

/* eslint-disable require-jsdoc */

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').find('.top-category')
        .remove();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

var isDesktop = function (element) {
    return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
};

var $userMenu = $('.navbar-header .user');
var $wtbMenu = $('.navbar-header .wtb');

/**
 * Behavior when mouse enters nav menu item
 */
function navMouseEnter() {
    if (isDesktop(this)) {
        // need to close all the dropdowns that are not direct parent of current dropdown
        $(this).addClass('show');
        $(this).children('.dropdown-menu').addClass('show');
        $(this).children('.dropdown-toggle').attr('aria-expanded', 'true');
    }
}

/**
 * Behavior when mouse enters nav menu item
 */
function navMouseLeave() {
    if (isDesktop(this)) {
        $(this).removeClass('show');
        $(this).children('.dropdown-menu').removeClass('show');
        $(this).children('.nav-link').attr('aria-expanded', 'false');
    }
}

/**
 * Init the default menu states
 */
function menuInit() {
    // console.log('running menuInit');
    if (window.matchMedia('(min-width: 75em)').matches) {
        // $userMenu.find('#account-sign-in, #store-locate').on('click', function (e) {
        $userMenu.find('#account-sign-in').on('click', function (e) {
            e.preventDefault();
        });
        $userMenu.find('#myaccount').unbind('click');
        $userMenu.find('.popover:not(.header__account)').attr('aria-hidden', 'true');
        $('.page').removeClass('screen-sized');
        $userMenu.find('.popover').removeClass('show');

        $userMenu.hoverIntent({
            over: accountMouseEnter,
            out: accountMouseLeave,
            timeout: 200
        });

        // console.log('$wtbMenu.get()=' + $wtbMenu.get());
        $wtbMenu.hoverIntent({
            over: wtbMouseEnter,
            out: wtbMouseLeave,
            timeout: 200
        });
    } else {
        $userMenu.find('#account-sign-in').unbind('click');
        $userMenu.unbind('mouseenter').unbind('mouseleave');
        $userMenu.removeProp('hoverIntent_t');
        $userMenu.removeProp('hoverIntent_s');

        $wtbMenu.unbind('mouseenter').unbind('mouseleave');
        $wtbMenu.removeProp('hoverIntent_t');
        $wtbMenu.removeProp('hoverIntent_s');

        $userMenu.find('#myaccount').unbind('click').on('click', function () {
            if ($userMenu.find('.popover').length > 0) {
                if ($userMenu.find('.popover').attr('aria-hidden') === 'true') {
                    // close main menu
                    $('.navbar>.close-menu>.close-button').trigger('click');
                    $('.navbar-nav .close-button').trigger('click');

                    $userMenu.find('.popover').attr('aria-hidden', 'false');
                    $userMenu.find('.popover').addClass('show');
                    if (window.matchMedia('(max-width: 48.063em)').matches) {
                        $('.page').addClass('screen-sized');
                    }
                } else {
                    $userMenu.find('.popover').attr('aria-hidden', 'true');
                    $userMenu.find('.popover').removeClass('show');
                    $('.page').removeClass('screen-sized');
                }
            }
        });

        $wtbMenu.find('.store-locate').unbind('click').on('click', function () {
            if ($wtbMenu.find('.popover').length > 0) {
                if ($wtbMenu.find('.popover').attr('aria-hidden') === 'true') {
                    // close main menu
                    $('.navbar>.close-menu>.close-button').trigger('click');
                    $('.navbar-nav .close-button').trigger('click');

                    $wtbMenu.find('.popover').attr('aria-hidden', 'false');
                    $wtbMenu.find('.popover').addClass('show');
                } else {
                    $wtbMenu.find('.popover').attr('aria-hidden', 'true');
                    $wtbMenu.find('.popover').removeClass('show');
                }
            }
        });

        $('body').on('click', function (e) {
            if ($userMenu.find('.popover.show').length > 0) {
                if ($(e.target).closest('.user').length === 0) {
                    $userMenu.find('.popover').removeClass('show');
                    $userMenu.attr('aria-hidden', 'true');
                }
            } else if ($wtbMenu.find('.popover.show').length > 0) {
                if ($(e.target).closest('.wtb').length === 0) {
                    $wtbMenu.find('.popover').removeClass('show');
                    $wtbMenu.attr('aria-hidden', 'true');
                }
            }
        });
    }
}

/**
 * Init the default touch menu
 */
function touchMenuInit() {
    // Init for 768 and up
    if (window.matchMedia('(min-width: 48em)').matches) {
        // Touch event for megamenu flyout toggles
        $('body').on('touchstart', '.dropdown-toggle', function (e) {
            e.preventDefault();
            if (window.matchMedia('(max-width: 991px')) { // only do this if the desktop-style menu is not in use
                // toggle closed if active / remove active menu states
                if ($(this).hasClass('isActive')) {
                    $(this).removeClass('isActive').attr('aria-expanded', false).parent()
                        .removeClass('show')
                        .find('.show')
                        .removeClass('show');
                    return true;
                } else { //eslint-disable-line
                    // Clear prev / set active state
                    $('.navbar-nav').find('.isActive').removeClass('isActive');
                    $(this).addClass('isActive').attr('aria-expanded', true).focus()
                        .parent()
                        .addClass('show')
                        .find('.dropdown-menu')
                        .addClass('show');
                    return false;
                }
            }
        }, false).on('click', '.dropdown-toggle', function (e) {
            // stop clickthrough if touch active
            if ($(this).hasClass('isActive') || window.matchMedia('(pointer: coarse)').matches) {
                e.preventDefault();
            }
        });
    }
}

/**
 * Reset nav states between desktop/mobile transition
 */
function mainNavReset() {
    $('.navbar-nav > li:not(.view-all-item)').each(function () {
        $(this).closest('.dropdown').children('.dropdown-menu').children('.top-category')
            .detach();
        $(this).closest('.dropdown').children('.dropdown-menu').children('.nav-menu')
            .detach();
        $(this).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
        $(this).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
        $(this).closest('.dropdown.show').removeClass('show');
        $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    });
}

/**
 * toggle site search
 */
function toggleSearch() {
    $('.search-trigger').on('click', function (e) {
        e.preventDefault();
        var headerHeight = $('header').height();
        var windowHeight = $(window).height();
        var windowScroll = $(window).scrollTop();

        var scrolledHeight = headerHeight > windowScroll ? headerHeight - windowScroll : 0;
        var height = windowHeight - scrolledHeight;
        var search = document.getElementsByClassName('search-overlay')[0];

        search.style.setProperty('--search-block-height', height + 'px');
        $('.search-overlay').addClass('js-active');
        $('.js-site-search-field').focus();
        $('body').addClass('modal-open');
        window.setTimeout(function () { // to give the search form time to be in position before triggering positioning of the suggestions wrapper
            $('#header-main').trigger('searchPanelOpened');
        }, 600);
    });
    $('.js-close-search').on('click', function () {
        $('.search-overlay').removeClass('js-active');
        $('body').removeClass('modal-open');
        $('.js-site-search-field').val('');
    });
}

/**
 * Behavior user hovers wtb header icon
 */
function wtbMouseEnter() {
    // console.log('running wtbMouseEnter and will try to open the popover');
    if ($('.navbar-header .wtb .popover').length > 0) {
        $('.navbar-header .wtb .popover').addClass('show');
        $('.wtb').attr('aria-expanded', 'true');
    }
}

/**
 * Behavior user leaves wtb header icon
 */
function wtbMouseLeave() {
    $('.navbar-header .wtb .popover').removeClass('show');
    $('.wtb').attr('aria-expanded', 'false');
}

/**
 * Behavior user hovers account header icon
 */
function accountMouseEnter() {
    if ($('.navbar-header .user .popover').length > 0) {
        $(this).find('.popover').addClass('show');
        $('.user').attr('aria-expanded', 'true');
    }
}

/**
 * Behavior user leaves account header icon
 */
function accountMouseLeave(e) {
    if (e.target.localName === 'input') {
        return;
    }

    $('.navbar-header .user .popover').removeClass('show');
    $('.user').attr('aria-expanded', 'false');
}

module.exports = function () {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        });

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this) || (isDesktop(this) && window.matchMedia('(pointer: coarse)').matches && window.matchMedia('max-width: 991px').matches)) {
                // $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var currCatName = $(this).text();
                var link = $(this).clone().addClass('dropdown-link').removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false')
                    .text($(this).attr('data-viewall'));
                li.append(link);

                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                closeMenu.append($('.close-menu .back').first().clone());
                $(this).parent().find('.dropdown-menu-list').first()
                    .append(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                $(this).parent().find('.nav-menu .back button').text(currCatName);
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                // $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        }).parent().hoverIntent({
            over: navMouseEnter,
            out: navMouseLeave,
            timeout: 200
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        // $('.modal-background').hide();
        $('.navbar-toggler').removeClass('menu-active');
        $('.page').removeClass('screen-sized');

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        // $('.modal-background').hide();
        $('.navbar-toggler').removeClass('menu-active');
        $('.page').removeClass('screen-sized');
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        var menuTop = $('.header-banner').outerHeight() + $('.header-main-inner').outerHeight();

        if (!$(this).hasClass('menu-active')) {
            $(this).addClass('menu-active');
            $('.main-menu').addClass('in').css('top', menuTop);
            // $('.modal-background').show();

            if ($userMenu.find('.popover').attr('aria-hidden') === 'false') {
                $userMenu.find('.popover').attr('aria-hidden', 'true');
                $userMenu.find('.popover').removeClass('show');
            }

            if ($wtbMenu.find('.popover').attr('aria-hidden') === 'false') {
                $wtbMenu.find('.popover').attr('aria-hidden', 'true');
                $wtbMenu.find('.popover').removeClass('show');
            }

            $('.page').addClass('screen-sized');
            $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        } else {
            $(this).removeClass('menu-active');
            $('.navbar>.close-menu>.close-button').trigger('click');
            $('.navbar-nav .close-button').trigger('click');
        }

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');
    });

    keyboardAccessibility('.navbar-header .user, .navbar-header .wtb',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover, .navbar-header .wtb .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                // $('.navbar-header .user .popover').removeClass('show');
                // $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.popover li.nav-item');
            return $popover;
        });

    menuInit();
    touchMenuInit();
    toggleSearch();

    // $('body').on('click', '#myaccount, .store-locate', function (e) {
    $('body').on('click', '#myaccount', function (e) {
        e.preventDefault();
    });

    $(window).smartresize(function () {
        mainNavReset();
        menuInit();
    });
};

// new 2024 gnav functionality...
function revertToParentContents() {
    $('.nav-content-wrapper').each(function () {
        var $this = $(this);
        var cgid = $this.data('cgid');
        $this.find('.nav-content-item, .sub-nav-link-list').removeClass('expanded visible');
        $this.find('.sub-banners').removeClass('expanded');
        $('.sub-sub-' + cgid).addClass('expanded visible');
        $('.sub-banners-' + cgid).addClass('expanded');
        $('.js-sub-sub-trigger-link').removeClass('active');
    });
}

function initNewGnav() {
    // show the upper subcategory's products on page load
    revertToParentContents();
    $('.sub-banners:first-child').addClass('expanded');
    $('.mega-nav > .dropdown-toggle').on('mouseover', function () {
        revertToParentContents();
    });
    $('.menu-mode-3').each(function () {
        // add 'active' to first sub-sub (e.g. solutions)
        $(this).find('.sub-sub-link:first-child').addClass('active');
        // add 'expanded' to sub-sub listmore item:
        $(this).find('.list-more-item:first-child').addClass('expanded');
        // add 'active' to first sub-sub-sub (e.g. chrome-os-collection)
        $(this).find('.list-more-sub-item:first-child').addClass('active');
        // add 'expanded' to the images item of that sub-sub-sub
        $(this).find('.list-items-' + $('.list-more-sub-item:first-child').data('cgid')).addClass('expanded');
        // show top-level (e.g. solutions) banners (no sub-sub-level banners available here)
        $(this).find('.sub-banners').addClass('expanded');
    });
    $('.js-sub-sub-trigger-link').on('mouseover', function () {
        $('.js-sub-sub-trigger-link').removeClass('active');
        $(this).addClass('active');
        var catCgid = $(this).data('cgid');
        var parentCgid = $(this).parents('.gnav-dropdown').data('cgid');
        var $topMenu = $('.gnav-dropdown.' + parentCgid);
        var $showProds;
        var $showBanners;
        var $catSubSub = $('.sub-sub-' + catCgid);
        if ($catSubSub.find('.gnav-product-card').length || $catSubSub.hasClass('sub-nav-links-list')) {
            $showProds = $catSubSub;
        } else {
            $showProds = $('.sub-sub-' + parentCgid);
        }
        $topMenu.find('.nav-content-item, .sub-nav-links-list').removeClass('expanded visible');
        $showProds.addClass('expanded');
        setTimeout(function () {
            $showProds.addClass('visible');
        }, 20);
        if ($('.sub-banners-' + catCgid).find ('.nav-banner').length) {
            $showBanners = $('.sub-banners-' + catCgid);
        } else {
            $showBanners = $('.sub-banners-' + parentCgid);
        }
        $('.sub-banners').removeClass('expanded');
        $showBanners.addClass('expanded');
    });
    // $('.gnav-dropdown').on('mouseleave', function () {
    //     revertToParentContents();
    // });
    $('.menu-mode-3 .js-sub-sub-trigger-link').on('mouseover', function () {
        $('.list-more-item').removeClass('expanded');
        $('.list-more-' + $(this).data('cgid')).addClass('expanded');
    });
    $('.menu-mode-3 .list-more-sub-item').on('mouseover', function () {
        $('.list-more-sub-item').removeClass('active');
        $(this).addClass('active');
        $('.list-item-images').removeClass('expanded');
        $('.list-items-' + $(this).data('cgid')).addClass('expanded');
    });
}

initNewGnav();



